import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import vfs from '../../../../../../../../../fonts/NimbusSanL/nimbus-san'
import DailySaleSummaryReportFilter from './DailySaleSummaryReportFilter'
import { useSnackbar } from 'notistack'
import '../../../../../../reportstyles.css'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { formattedDate } from 'app/utils/components/FormatDateTime/FormatDateTime'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { ReportHeaderRight } from 'app/utils/components/ReportHeaderRight'

const DailySaleSummaryReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const { dailySalesSummary, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const { authUser } = useJumboAuth()
    const now =new Date()

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }
    const headers = React.useMemo(
        () => [
            'Date',
            'Cash Sales',
            'Credit Card Payment',
            'Credit Sales',
            'Total Sales',
            'Total Refund',
            'Tax',
            'Discount',
            'Refund\nDiscount',
            'Net Total',
        ],
        []
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const itemsPerPage = 25
    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }
    const [pdfTemplate, setpdfTemplate] = useState({})
    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    formattedDate(d.date),
                    formatCurrency(d.cashSales),
                    formatCurrency(d.creditCardPayment),
                    formatCurrency(d.creditSales),
                    formatCurrency(d.totalSale),
                    formatCurrency(d.salesRefunded),
                    formatCurrency(d.tax),
                    formatCurrency(d.discount),
                    formatCurrency(d.refundedDiscount),
                    formatCurrency(d.total),
                ]
            })
        },
        []
    )
    const reportRow = [
        { text: 'Grand Total', style: 'rowHeading' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalCashSales), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalCreditCardPayment), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalCreditSales), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalSale), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalSalesRefunded), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalTax), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalDiscount), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalRefundedDiscount), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.reportSummary?.totalTxnTotal), style: 'rightAlign' },
    ]
    const pageRow = [
        { text: 'Page Total', style: 'rowHeading' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalCashSales), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalCreditCardPayment), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalCreditSales), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalSale), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalSalesRefunded), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalTax), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalDiscount), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalRefundedDiscount), style: 'rightAlign' },
        { text: formatCurrency(dailySalesSummary.pageSummary?.totalTxnTotal), style: 'rightAlign' },
    ]
    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [15, 60, 15, 60],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                // Content for every page
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [70, 70, 70, 70, 70, 70, 70, 70, 70, 70],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [20, 10, 30, 10],
                            },
                        ],
                    }
                }

                // Content for the last page
                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [90, 70, 70, 70, 70, 70, 70, 50, 70, 70],
                                body: [
                                    pageRow.map((cell) => ({ text: cell.text, style: cell.style })),
                                    reportRow.map((cell) => ({ text: cell.text, style: cell.style })),
                                ],
                            },
                            margin: [20, 10, 30, 10],
                        },
                    ],
                }
            },
            content: [
                {
                    columns: ReportHeaderRight(
                        imageData,
                        authUser,
                        now,
                        'Daily Sale Summary Report',
                        [220, 0, 0, 0], // titleMargin
                        [0, 0, 20, 0]  // stackMargin (custom)
                    ),
                },                
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    style: 'withMargin',
                    alignment: 'center',
                    table: {
                        headerRows: 1,
                        widths: [90, 70, 70, 70, 70, 70, 70, 50, 70, 70],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...parseToPdfData(dailySalesSummary.data).map((row) =>
                                row.map((cell, index) => ({
                                    text: cell,
                                    style: ['cell', index !== 0 ? 'rightAlign' : 'cell'],
                                }))
                            ),
                        ],
                    },
                },
            ],

            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, dailySalesSummary])

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    useEffect(() => {
        if (dailySalesSummary?.length <= 0) return
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, dailySalesSummary, pdfTemplateDefault])

    const create = async () => {
        // This will load the data
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(
            `Daily_Sale_Summary_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`
        )
    }, [pdfTemplate])
    const totalPages = Math.ceil(dailySalesSummary.totalCount / itemsPerPage)
    return (
        <Div>
            <Div>
                <h1 className='report-heading'>Daily Sale Summary Report</h1>
                <Div className='report-loading-button'>
                    <LoadingButton
                        sx={{ float: 'right', marginRight: '90px', marginTop: '-55px' }}
                        onClick={create}
                        size='small'
                        type='submit'
                        variant='contained'>
                        <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
                    </LoadingButton>
                    <LoadingButton
                        sx={{ float: 'right', marginRight: '9px', marginTop: '-55px' }}
                        onClick={handleFilterClick}
                        size='small'
                        type='submit'
                        variant='contained'>
                        <FilterAltIcon fontSize='medium' />
                    </LoadingButton>
                </Div>
            </Div>
            {isFilterVisible && <DailySaleSummaryReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && dailySalesSummary.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        {headers.map((h, index) => {
                                            return <th key={h}>{h}</th>
                                        })}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {dailySalesSummary.data?.map((d, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: '13px' }}>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {formattedDate(d.date)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.cashSales)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.creditCardPayment)}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                    {formatCurrency(d.creditSales)}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                    {formatCurrency(d.totalSale)}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                    {formatCurrency(d.salesRefunded)}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                    {formatCurrency(d.tax)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.discount)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.refundedDiscount)}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                    {formatCurrency(d.total)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {dailySalesSummary.pageSummary && (
                                        <tr style={{ fontSize: '13px' }}>
                                            <td colSpan={1} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                Page Total
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalCashSales ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(
                                                    dailySalesSummary.pageSummary.totalCreditCardPayment ?? ''
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalCreditSales ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalSale ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalSalesRefunded ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalTax ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalDiscount ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(
                                                    dailySalesSummary.pageSummary.totalRefundedDiscount ?? ''
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.pageSummary.totalTxnTotal ?? '')}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                    {currentPage === totalPages && dailySalesSummary.reportSummary && (
                                        <tr>
                                            <td colSpan={1} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                Grand Total
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalCashSales)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalCreditCardPayment)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalCreditSales)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalSale)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalSalesRefunded)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalTax)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalDiscount)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalRefundedDiscount)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySalesSummary.reportSummary.totalTxnTotal)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(dailySalesSummary.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={0}
                            boundaryCount={2}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default DailySaleSummaryReportLayout
