import React, { useCallback } from 'react'
import { ListItemAvatar, ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import Chip from '@mui/material/Chip'
import { useNavigate } from 'react-router-dom'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import Span from '@jumbo/shared/Span'

const LeftInfoPanelItem = ({ item }) => {
    const navigate = useNavigate()
    const onlineShipment = false

    const handleShipmentListClick = useCallback(
        ({ item }) => {
            navigate(`/store/shipments/orders/details/${item.orderId}/${onlineShipment}`, {
                state: { orderId: item.orderId, onlineShipment: onlineShipment },
            })
        },
        [navigate]
    )

    return (
        <ListItemButton
            component={'li'}
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleShipmentListClick({ item })}>
            <ListItemAvatar>
                <DirectionsBoatIcon />
            </ListItemAvatar>
            <ListItemText
                sx={{ flexBasis: '30%' }}
                primary={
                    <Typography variant='h5' mb={0.5} color='text.primary'>
                        <Chip label={item.orderNo} size={'small'} color={'success'} sx={{ mb: 1 }} />
                    </Typography>
                }
                secondary={
                    <>
                        <Typography variant={'body1'} mb={0.5} mr={1}>
                            <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                {`${formatDateTime(item.orderDate)}`}
                            </Span>{' '}
                            <br />
                            <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                {`Stock In by ${item.supplierName} (${item.orderType})`}{' '}
                            </Span>{' '}
                        </Typography>
                    </>
                }
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        {formatCurrency(item.txnTotal)}
                    </Typography>
                }
                secondary={'Total'}
            />
            <ListItemText
                primary={
                    <Typography variant='body1' component={'span'} color='text.primary'>
                        {formatCurrency(item.totalPayment)}
                    </Typography>
                }
                secondary={'paid'}
            />
        </ListItemButton>
    )
}

export default LeftInfoPanelItem
