import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    filters: {},
    users: { data: [], totalCount: 0 },
    userLookups: { data: [], totalCount: 0 },
    userTypes: { data: [], totalCount: 0 },
    userTypeConfig: { id: null, readOnly: false },
    branchUsers: { data: [], totalCount: 0 }, // For Report dropdown
    linkedBranches: { data: [], totalCount: 0 },
    userPermissions: { data: [], totalCount: 0 },
    updateUserInProgress: false,
    loading: false,
    refreshing: false,
    error: null,
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserFilters: (state, action) => {
            state.filters = action.payload
        },
        resetUserFilters: (state) => {
            state.filters = {}
        },
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getUserTypeConfig: (state, action) => {
            state.userTypeConfig = action.payload
        },
        updateUserTypeConfig: (state, action) => {
            state.userTypeConfig = action.payload
        },
        getUsers: (state, action) => {
            state.users = action.payload
            state.loading = false
            state.refreshing = false
        },
        getUserLookup: (state, action) => {
            state.userLookups = action.payload
            state.loading = false
        },
        getExistingUserById: (state, action) => {
            const index = state.users.data.findIndex((user) => user.userId === action.payload.userId)
            if (index >= 0) state.users.data[index] = action.payload
            else {
                state.users.data.push(action.payload)
                state.users.totalCount++
            }
            state.loading = false
        },
        addNewUser: (state, action) => {
            state.users.data.push(action.payload)
            state.users.totalCount++
            state.loading = false
        },
        updateExistingUser: (state, action) => {
            const index = state.users.data.findIndex((user) => user.userId === action.payload.userId)
            if (index >= 0) state.users.data[index] = action.payload
            state.loading = false
        },
        removeUser: (state, action) => {
            const index = state.users.data.findIndex((user) => user.userId === action.payload.userId)
            if (index >= 0) {
                state.users.data.splice(index, 1)
                state.users.totalCount--
            }
            state.loading = false
        },

        getLinkedBranches: (state, action) => {
            state.linkedBranches = action.payload
            state.loading = false
        },
        linkNewBranch: (state, action) => {
            state.linkedBranches.data.push(action.payload)
            state.linkedBranches.totalCount++
            state.loading = false
        },
        unLinkBranch: (state, action) => {
            const index = state.linkedBranches.data.findIndex((branch) => branch.branchId === action.payload.branchId)
            if (index >= 0) {
                state.linkedBranches.data.splice(index, 1)
                state.linkedBranches.totalCount--
            }
            state.loading = false
        },
        getUserTypeLookup: (state, action) => {
            state.userTypes.data = action.payload
            if (state.userTypes.data) state.userTypes.totalCount = state.userTypes.data.length
            state.loading = false
        },
        getExistingUserTypeLookupById: (state, action) => {
            const index = state.userTypes.data.findIndex((userType) => userType.id === action.payload.id)
            if (index >= 0) state.userTypes.data[index] = action.payload
            else {
                state.userTypes.data.push(action.payload)
                state.userTypes.totalCount++
            }
            state.loading = false
        },
        getBranchUserLookup: (state, action) => {
            state.branchUsers = action.payload
            state.loading = false
        },
        getExistingBranchUserLookupById: (state, action) => {
            const index = state.branchUsers.data.findIndex((user) => user.userId === action.payload.userId)
            if (index >= 0) state.branchUsers.data[index] = action.payload
            else {
                state.branchUsers.data.push(action.payload)
            }
            state.loading = false
        },

        getUserPermissions: (state, action) => {
            state.userPermissions = action.payload
            state.loading = false
        },
        updateUserPermissionStart: (state, action) => {
            state.updateUserInProgress = true
            state.loading = true
            state.error = null
        },
        updateExistingUserPermission: (state, action) => {
            const index = state.userPermissions.data.findIndex((user) => user.userId === action.payload.userId)
            if (index >= 0) {
                state.userPermissions.data.splice(index, 1)
                state.userPermissions.totalCount--
            }
            state.loading = false
            state.updateUserInProgress = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.userId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    setUserFilters,
    resetUserFilters,
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getUserTypeConfig,
    updateUserTypeConfig,
    getUsers,
    getUserLookup,
    getExistingUserById,
    getUserTypeLookup,
    getExistingUserTypeLookupById,
    addNewUser,
    updateExistingUser,
    removeUser,
    getUserPermissions,
    getLinkedBranches,
    linkNewBranch,
    unLinkBranch,
    getBranchUserLookup,
    getExistingBranchUserLookupById,
    updateUserPermissionStart,
    updateExistingUserPermission,
} = userSlice.actions
export default userSlice.reducer

export const getUserList = (filter, refresh = false) => {
    let url = `/User/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getUsers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserLookupList = (filter) => {
    let url = `/User/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUserLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserById = (filter) => {
    let url = `/User/${filter.userId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingUserById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserTypeLookupList = (filter) => {
    let url = filter ? `/User/type/lookup/list?search=${filter}` : `/User/type/lookup/list`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUserTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserTypeLookupById = (filter) => {
    let url = `/User/type/lookup/${filter.userTypeId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingUserTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addUser = (user) => {
    let url = `/User/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: user,
        onStart: apiRequested.type,
        onSuccess: addNewUser.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateUser = (user) => {
    let url = `/User/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: user,
        onStart: apiRequested.type,
        onSuccess: updateExistingUser.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteUser = (user) => {
    let url = `/User/delete`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: user,
        onStart: apiRequested.type,
        onSuccess: removeUser.type,
        onError: apiRequestedFailed.type,
    })
}

export const getLinkedBranchList = (filter) => {
    let url = `/user/${filter.userId}/company/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getLinkedBranches.type,
        onError: apiRequestedFailed.type,
    })
}

export const linkUserBranch = (filter) => {
    let url = `/user/${filter.userId}/company/branch/link`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: linkNewBranch.type,
        onError: apiRequestedFailed.type,
    })
}

export const unLinkUserBranch = (filter) => {
    let url = `/user/${filter.userId}/company/branch/unlink`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: unLinkBranch.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchUserLookupList = (filter) => {
    let url = filter ? `/User/branch/lookup/list?search=${filter}` : `/User/branch/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getBranchUserLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchUserLookupById = (filter) => {
    let url = `/User/branch/lookup/${filter.userId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingBranchUserLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserPermissionList = (filter) => {
    let url = `/User/${filter.userId}/permission/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUserPermissions.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateUserPermission = (filter, userId) => {
    let url = `/User/${userId}/permission/save`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: filter,
        onStart: updateUserPermissionStart.type,
        onSuccess: updateExistingUserPermission.type,
        onError: apiRequestedFailed.type,
    })
}
