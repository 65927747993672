import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectBox } from 'devextreme-react'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { getUserTypeLookupList, resetUserFilters, setUserFilters } from '../../../../redux/store/features/users'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../services/util-services'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import companyAndBranchServices from 'app/services/settings/company/companyAndBranchServices'
import useDebouncedInput from 'app/pages/reports/hooks/useDebouncedInput'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'

const UserGridFilter = ({ mutation, display, filterIconColor, initialValues }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    const [companySelectedData, setCompanySelectedData] = useState(initialValues?.companyId || null)
    const [branchSelectedData, setBranchSelectedData] = useState(initialValues?.branchId || null)

    const {
        value: searchValue,
        debouncedValue: debouncedUserTypeValue,
        onChange: onUserTypeSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getUserTypeLookupList('' || searchValue))
    }, [debouncedUserTypeValue])

    const { userTypes } = useSelector((state) => state.users)

    const [filter, setFilter] = useState({ userType: initialValues.userType })

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const userTypeStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'id',
                    data: userTypes.data,
                    totalCount: userTypes.length,
                }),
                reshapeOnPush: true,
            }),
        [userTypes]
    )

    const onCompanyValueChanged = async (e) => {
        if (e) {
            setCompanySelectedData(e.companyId)
        } else {
            setCompanySelectedData(null)
            setBranchSelectedData(null)
        }
    }

    const onBranchValueChanged = (e) => {
        if (e) {
            setBranchSelectedData(e)
        } else {
            setBranchSelectedData(null)
        }
    }

    const companyLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getCompanyLookupList({ companyName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const companyLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'companyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await companyLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        try {
                            const response = await companyAndBranchServices.getCompanyLookupById(key)
                            return response
                        } catch (error) {
                            console.error(error)
                            return null
                        }
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const branchLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (utilServices.isNullOrUndefined(companySelectedData)) return { totalCount: 0, data: [] }
                return await companyAndBranchServices.getBranchLookupList({
                    companyId: companySelectedData,
                    branchName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [companySelectedData]
    )

    const branchLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'branchId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await branchLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        try {
                            const response = await companyAndBranchServices.getBranchById(key)
                            return response
                        } catch (error) {
                            console.error(error)
                            return null
                        }
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [companySelectedData]
    )

    const onFormSubmit = async (firstName, lastName, email) => {
        const filters = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            ...filter,
            companyId: utilServices.isNullOrUndefined(companySelectedData) ? null : companySelectedData,
            branchId: utilServices.isNullOrUndefined(branchSelectedData) ? null : branchSelectedData.branchId,
            sort: 'FirstName',
            isDescending: false,
        }
        dispatch(setUserFilters(filters))
        await mutation.mutate(filters)
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.firstName, data.lastName, data.email)
        setSubmitting(false)
    }

    const handleClear = (resetForm) => {
        dispatch(resetUserFilters())
        filterIconColor(false)
        setFilter({ userType: null })
        setCompanySelectedData(null)
        setBranchSelectedData(null)
        resetForm({
            values: {
                firstName: '',
                lastName: '',
                email: '',
            },
        })
    }

    const onUserTypeChanged = (value) => {
        setFilter({ ...filter, userType: value?.id })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting, resetForm }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ borderRadius: 0, display: display }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>First Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 200 }} size='small' name='firstName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Last Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 200 }} size='small' name='lastName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Email</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 200 }} size='small' name='email' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>User Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 200 }}
                                        dataSource={userTypeStore}
                                        displayExpr={'typeName'}
                                        value={filter.userType}
                                        searchEnabled={true}
                                        onValueChange={onUserTypeChanged}
                                        onInput={onUserTypeSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Company</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 200 }}
                                        dataSource={companyLookupsStore}
                                        value={companySelectedData}
                                        displayExpr={'companyName'}
                                        searchEnabled={true}
                                        onValueChange={onCompanyValueChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Branch</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 200 }}
                                        dataSource={branchLookupStore}
                                        value={branchSelectedData}
                                        displayExpr={'branchName'}
                                        searchEnabled={true}
                                        onValueChange={onBranchValueChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        name='search'
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={() => handleClear(resetForm)}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}
export default UserGridFilter
