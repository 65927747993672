import { selectItemById } from 'app/redux/store/features/auditlog'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Typography } from '@mui/material'
import AuditLogViewForm from './AuditLogViewForm'
import IconButton from '@mui/material/IconButton'
import WestIcon from '@mui/icons-material/West'
import Stack from '@mui/material/Stack'

const AuditLogView = () => {
    const navigate = useNavigate()
    let { auditLogId } = useParams()
    const { auditLog, loading, error } = useSelector((state) => state.auditLog)
    const SingleAuditLog = selectItemById(auditLog, auditLogId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <IconButton
                            onClick={() =>
                                navigate(`/company/auditlogs`, {
                                    state: { navigateFrom: 'cancel-detail-auditLog' },
                                })
                            }
                            sx={{ color: 'white' }}>
                            <WestIcon />
                        </IconButton>
                        <Typography color='common.white' variant='primary'>
                            Details Audit Log
                        </Typography>
                    </Stack>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <AuditLogViewForm SingleAuditLog={SingleAuditLog} />
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AuditLogView
