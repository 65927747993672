import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import UserGridFilter from './components/UserGridFilter'
import UserGridData from './components/UserGridData'
import { getUserList } from '../../../redux/store/features/users'
import { useDispatch, useSelector } from 'react-redux'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'

const UserGrid = () => {
    const pageSize = 25
    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const position = { of: '#dataGrid' }
    const { users, refreshing, filters } = useSelector((state) => state.users)

    const shouldPersistFilters = ['cancel-add-user', 'cancel-update-user', 'add-user', 'update-user'].includes(
        state?.navigateFrom
    )

    const [filter, setFilter] = useState(() =>
        shouldPersistFilters && filters
            ? { ...filters }
            : {
                  firstName: '',
                  lastName: '',
                  email: '',
                  sort: 'FirstName',
                  isDescending: false,
              }
    )

    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!state?.navigateFrom) {
            setApiCallInProgress(true)
            dispatch(getUserList({ ...filter, skip: 0, take: pageSize }))
        } else if (
            shouldPersistFilters &&
            filters &&
            Object.keys(filters).length > 0 &&
            (filters.firstName ||
                filters.lastName ||
                filters.email ||
                (filters.userType !== null && filters.userType !== undefined) ||
                (filters.companyId !== null && filters.companyId !== undefined) ||
                (filters.branchId !== null && filters.branchId !== undefined))
        ) {
            setuserGrid({ type: 'set-grid-filter', payload: true })
        }
    }, [state?.navigateFrom, filter, dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function userReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [userGrid, setuserGrid] = React.useReducer(
        userReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getUserList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        setApiCallInProgress(true)
        dispatch(getUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getUserList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setuserGrid({ type: 'set-grid-filter', payload: value })
        },
        [setuserGrid]
    )

    const onAddUser = () => {
        navigate('/security/users/addUser')
    }

    const setFilterIconColor = React.useCallback(
        (value) => {
            setuserGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setuserGrid]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.5,
                            mr: 1,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    User List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
                pl: 1,
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        size={'large'}
                        fontSize={'large'}
                        variant={'contained'}
                        color={'#e0e0e0'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: '#e0e0e0',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddUser}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                userGrid.filterIconColor && !userGrid.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!userGrid.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <UserGridFilter
                    initialValues={filter}
                    mutation={applyFilter}
                    display={userGrid.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Div id='dataGrid'>
                <Grid>
                    <UserGridData
                        onRefresh={onRefresh}
                        onPageChange={onPageChange}
                        onColumnSort={onColumnSort}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        apiCallInProgress={apiCallInProgress}
                        setApiCallInProgress={setApiCallInProgress}
                    />
                </Grid>
                {users.totalCount !== 0 && (
                    <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                )}
            </Div>
        </JumboCardQuick>
    )
}

export default UserGrid
