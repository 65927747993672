import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services.js'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { getTransferById, selectItemById } from 'app/redux/store/features/transfers.js'
import UpdateTransferForm from './UpdateTransferForm.js'
import TransferItemGrid from './TransferItemGrid/TransferItemGrid.js'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import requestServices from '../../../../../../services/pages/company/requestServices/requestServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import { formatDate } from '../../../../../../utils/components/FormatDateTime/FormatDateTime'
import companyAndBranchServices from '../../../../../../services/settings/company/companyAndBranchServices'
import useApp from '../../../../../../hooks/useApp'
import IconButton from '@mui/material/IconButton'
import WestIcon from '@mui/icons-material/West'
import Stack from '@mui/material/Stack'

const UpdateTransfer = () => {
    const [tabTitle, setTabTitle] = React.useState('details')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { transferInventoryId } = state
    const { transfers } = useSelector((state) => state.transfers)
    const transfer = selectItemById(transfers, transferInventoryId)
    const { enqueueSnackbar } = useSnackbar()
    const { companySelection } = useApp()

    useEffect(() => {
        if (utilServices.isNullOrUndefined(transfer.transferInventoryId))
            dispatch(getTransferById({ transferInventoryId: transferInventoryId }))
    }, [])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const transferRequestLookupList = useCallback(async (searchTerm) => {
        try {
            return await requestServices.getTransferRequestLookupList({
                receivingBranchId: transfer.fkReceivingBranchId,
                search: searchTerm,
            })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const transferRequestLookupById = useCallback(async (key) => {
        try {
            return await requestServices.getTransferRequestLookupById(key, {
                receivingBranchId: transfer.fkReceivingBranchId,
            })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const transferRequestLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'transferRequestId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await transferRequestLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await transferRequestLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const transferRequestEditorOptions = {
        dataSource: transferRequestLookupStore,
        valueExpr: 'transferRequestId',
        itemTemplate: function (data) {
            return data
                ? data.transferRequestNo + ', ' + data.sourceBranchName + ', ' + formatDate(data.transferRequestDate)
                : ''
        },
        displayExpr: function (data) {
            return data
                ? data.transferRequestNo + ', ' + data.sourceBranchName + ', ' + formatDate(data.transferRequestDate)
                : ''
        },
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const branchLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getBranchLookupList({
                companyId: companySelection?.companyId,
                branchName: searchTerm,
            })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const branchLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getBranchById(companySelection?.companyId, key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const branchLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'branchId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await branchLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await branchLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const receivingBranchEditorOptions = {
        dataSource: branchLookupStore,
        displayExpr: 'branchName',
        valueExpr: 'branchId',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const senderBranchEditorOptions = {
        dataSource: branchLookupStore,
        displayExpr: 'branchName',
        valueExpr: 'branchId',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const onTabSelectionChanged = useCallback((e, newValue) => {
        setTabTitle(newValue)
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <IconButton
                            onClick={() =>
                                navigate(`/company/transfers`, {
                                    state: { navigateFrom: 'cancel-update-user' },
                                })
                            }
                            sx={{ color: 'white' }}>
                            <WestIcon />
                        </IconButton>
                        <Typography color='common.white' variant='primary'>
                            Update Transfer{' '}
                            {'(' +
                                (transfer?.senderBranchName ?? '') +
                                ' to ' +
                                (transfer?.receivingBranchName ?? '') +
                                ')' +
                                ' - ' +
                                (transfer?.transferNo ?? '')}
                        </Typography>
                    </Stack>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList
                        sx={{ bgcolor: 'background.default', m: 2, mb: 0, marginLeft: '1.4%', marginRight: '1.4%' }}
                        onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Items'} value={'items'} sx={{ width: '160px' }} />
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateTransferForm
                            transferInventoryId={transferInventoryId}
                            transfer={transfer}
                            transferRequestEditorOptions={transferRequestEditorOptions}
                            receivingBranchEditorOptions={receivingBranchEditorOptions}
                            handleError={handleError}
                            senderBranchEditorOptions={senderBranchEditorOptions}
                        />
                    </TabPanel>
                    <TabPanel value='items'>
                        <TransferItemGrid
                            transferInventoryId={transferInventoryId}
                            transferStatus={transfer.transferStatus}
                            fkSenderBranchId={transfer.fkSenderBranchId}
                            inventoryItems={transfer.inventoryItems}
                        />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateTransfer
