import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
    ColumnChooser,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useDispatch, useSelector } from 'react-redux'
import { deleteInventory, getInventoryById } from '../../../../redux/store/features/inventory'
import { useSnackbar } from 'notistack'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'
import { enforceMinWidths, recalculateWidths } from 'app/utils/components/ColumnWidth/ColumnWidth'

const InventoryDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    columnChooserOpen,
    setColumnChooserOpen,
    btnRef,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const { inventories, loading, error } = useSelector((state) => state.inventories)
    const dateColumns = ['modifiedDate']
    const [sorting, setSorting] = useState([{ columnName: 'inventoryNo', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const [hiddenColumns, setHiddenColumns] = useState(['modifiedDate', 'invTypeName', 'supplierName', 'description'])
    const gridContainerRef = useRef(null)
    const targetDiv = useRef(null)
    const [tableColumnVisibilityColumnExtensions] = useState([{ columnName: 'Action', togglingEnabled: false }])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getInventoryById({ inventoryId: id }))
        navigate(`/store/inventory/edit/${id}`, { state: { inventoryId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.inventoryId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.inventoryId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveInventory(row.inventoryId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.inventoryId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.inventoryNo}
            </Link>
        )
    }

    const hideDialogAndRefreshInventoryList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Inventory data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveInventory = async (inventoryId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteInventory({ inventoryId: inventoryId }))
                hideDialogAndRefreshInventoryList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'inventoryNo', title: 'Inv Code', getCellValue: renderLinkCell },
        { name: 'inventoryName', title: 'Name' },
        { name: 'barcode', title: 'Barcode' },
        { name: 'totalStock', title: 'Stock' },
        { name: 'salePrice', title: 'S.Price', getCellValue: (row) => formatCurrency(row.salePrice) },
        { name: 'purchasePrice', title: 'P.Price', getCellValue: (row) => formatCurrency(row.purchasePrice) },
        { name: 'modifiedDate', title: 'Date Modified' }, ///
        { name: 'categoryName', title: 'Category' },
        { name: 'invTypeName', title: 'Inventory Type' }, ///
        { name: 'description', title: 'Description' },
        { name: 'supplierName', title: 'Supplier' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'inventoryNo', width: min ? 100 : 127.594, minWidth: 100, wordWrapEnabled: true },
            { columnName: 'inventoryName', width: min ? 180 : 287.094, minWidth: 180, wordWrapEnabled: true },
            { columnName: 'barcode', width: min ? 130 : 207.344, minWidth: 130 },
            { columnName: 'totalStock', width: min ? 90 : 95.6875, minWidth: 90 },
            { columnName: 'salePrice', align: 'right', width: min ? 90 : 111.641, minWidth: 90 },
            { columnName: 'purchasePrice', align: 'right', width: min ? 90 : 111.641, minWidth: 90 },
            { columnName: 'modifiedDate', width: min ? 130 : 207.344, minWidth: 130, wordWrapEnabled: true },
            { columnName: 'categoryName', wordWrapEnabled: true, width: min ? 120 : 175.438, minWidth: 120 },
            { columnName: 'invTypeName', wordWrapEnabled: true, width: min ? 120 : 175.438, minWidth: 120 },
            { columnName: 'description', wordWrapEnabled: true, width: min ? 120 : 175.438, minWidth: 120 },
            { columnName: 'supplierName', wordWrapEnabled: true, width: min ? 120 : 191.391, minWidth: 120 },
            { columnName: 'Action', align: 'center', width: min ? 90 : 111.641, minWidth: 90 },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        if (gridContainerRef.current && columnChooserOpen) {
            gridContainerRef.current.click()
        }
        if (hiddenColumns.length === 2) {
            setColumnWidths(columnWidth)
        } else {
            const containerWidth = targetDiv.current ? targetDiv.current.offsetWidth : 0
            const recalculated = recalculateWidths(columnWidths, containerWidth, hiddenColumns)
            setColumnWidths(enforceMinWidths(recalculated))
        }
    }, [hiddenColumns, columnWidth, columnChooserOpen])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    const OverlayComponent = (props) => <ColumnChooser.Overlay {...props} target={targetDiv.current} />
    const handleColumnWidthsChange = (newWidths) => {
        const enforcedWidths = enforceMinWidths(newWidths)
        setColumnWidths(enforcedWidths)
    }

    const HiddenToolbar = (props) => {
        return <div {...props} style={{ display: 'none' }}></div>
    }

    const ToggleButton = useCallback(
        (props) => (
            <IconButton
                onClick={() => {
                    setColumnChooserOpen((prev) => !prev)
                    props.onToggle()
                }}
                buttonref={btnRef.current}
                ref={gridContainerRef}></IconButton>
        ),
        [setColumnChooserOpen, btnRef, gridContainerRef]
    )

    return (
        <Paper style={{ position: 'relative' }}>
            <div ref={targetDiv}></div>
            <Grid rows={inventories.data} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={inventories.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={handleColumnWidthsChange}
                    defaultColumnWidths={columnWidth}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumns}
                    onHiddenColumnNamesChange={setHiddenColumns}
                    columnExtensions={tableColumnVisibilityColumnExtensions}
                />
                <Toolbar rootComponent={HiddenToolbar} />
                <ColumnChooser overlayComponent={OverlayComponent} toggleButtonComponent={ToggleButton} />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default InventoryDataGrid
