import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateOrder } from 'app/redux/store/features/orders'
import FreightAmountPaidByTypeService from './FreightAmountPaidByTypeService'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import RadioGroup from 'devextreme-react/radio-group'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import { Divider } from '@mui/material'
import { useMediaQuery } from '@mui/material'

const UpdateDraftOrderForm = (props) => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.orders)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Draft Order data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/shipments/draftorders')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () =>
            navigate('/store/shipments/draftorders', {
                state: { navigateFrom: 'cancel-update-order' },
            }),
    }

    const priorityEntitiesDiscountFixed = [
        { id: true, text: 'Discount Fixed' },
        { id: false, text: 'Discount Percentage' },
    ]

    const onSelectionChangedDiscountFixed = (e) => {
        props.order.isDiscountFixed = e.value
    }

    const priorityEntitiesDiscountEach = [
        { id: true, text: 'Discount Each Item' },
        { id: false, text: 'Discount Single Item' },
    ]

    const onSelectionChangedDiscountEach = (e) => {
        props.order.isDiscountEach = e.value
    }

    const FreightAmountPaidByTypeEditorOptions = {
        dataSource: FreightAmountPaidByTypeService.getFreightAmountPaidByType(),
        displayExpr: 'freightAmountPaidByType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const supplierLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierLookupList({ supplierName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return supplierLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const supplierEditorOptions = useMemo(
        () => ({
            dataSource: supplierLookupStore,
            displayExpr: 'supplierName',
            valueExpr: 'supplierId',
            searchEnabled: true,
            showClearButton: true,
        }),
        [supplierLookupStore]
    )

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const onUpdateDraftOrder = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(
            updateOrder({
                ...props.order,
                orderDate: props.order.orderDate.toFilterStartDateTime(),
                expectedDate: props.order.expectedDate.toFilterStartDateTime(),
            })
        )
    }, [props.order])

    return (
        <form onSubmit={handleSubmit(onUpdateDraftOrder)}>
            <Form
                mode='form'
                formData={props.order}
                showColonAfterLabel={false}
                readOnly={props?.orderStatus === 'Approved' ? true : false}
                labelLocation={labelLocation}>
                <GroupItem>
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                        <GroupItem caption='Order Details' cssClass='form-group-item'>
                            <GroupItem cssClass='form-group-item-margin'>
                                <SimpleItem
                                    editorType='dxDateBox'
                                    dataField='orderDate'
                                    editorOptions={{
                                        displayFormat: 'shortDateShortTime',
                                        type: 'datetime',
                                        pickerType: 'calendar',
                                        useMaskBehavior: true,
                                    }}>
                                    <Label text='Order Date' />
                                </SimpleItem>
                                <SimpleItem dataField='purchaseOrderNo'>
                                    <Label text='Purchase Order No' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='expectedDate'
                                    editorType='dxDateBox'
                                    editorOptions={{
                                        displayFormat: 'shortDateShortTime',
                                        type: 'datetime',
                                        pickerType: 'calendar',
                                        useMaskBehavior: true,
                                    }}>
                                    <Label text='Delivery Date' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='fkSupplierId'
                                    editorType='dxSelectBox'
                                    editorOptions={supplierEditorOptions}>
                                    <Label text='Supplier' />
                                </SimpleItem>
                                <SimpleItem dataField='comments' editorType='dxTextArea'>
                                    <Label text='Comments' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <GroupItem caption='Freight Charges' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                        <Label text='Freight Amount' />
                                    </SimpleItem>
                                    <SimpleItem dataField='freightBNumber'>
                                        <Label text='Bity Number' />
                                    </SimpleItem>
                                    <SimpleItem
                                        editorType='dxSelectBox'
                                        dataField='freightAmountPaidByType'
                                        editorOptions={FreightAmountPaidByTypeEditorOptions}
                                    />
                                    <SimpleItem dataField='freightComments'>
                                        <Label text='Bity Provider' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Configuration' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='isDiscountFixed' label={{ visible: false }}>
                                        <RadioGroup
                                            defaultValue={props.order.isDiscountFixed}
                                            items={priorityEntitiesDiscountFixed}
                                            valueExpr='id'
                                            readOnly={true}
                                            displayExpr='text'
                                            layout='vertical !important'
                                            onValueChanged={onSelectionChangedDiscountFixed}></RadioGroup>
                                    </SimpleItem>
                                    <GroupItem>
                                        <Divider sx={{ my: 0.5 }} />
                                    </GroupItem>
                                    <SimpleItem dataField='isDiscountEach' label={{ visible: false }}>
                                        <RadioGroup
                                            readOnly={true}
                                            items={priorityEntitiesDiscountEach}
                                            valueExpr='id'
                                            displayExpr='text'
                                            layout='vertical !important'
                                            defaultValue={props.order.isDiscountEach}
                                            onValueChanged={onSelectionChangedDiscountEach}></RadioGroup>
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem>
                    <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                    <ButtonItem
                        buttonOptions={{
                            disabled: loading || props?.orderStatus === 'Approved' ? true : false,
                            icon: loading ? 'refresh' : 'check',
                            ...submitButtonOptions,
                        }}
                        horizontalAlignment='right'
                    />
                    <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                </GroupItem>
            </Form>
        </form>
    )
}

export default UpdateDraftOrderForm
