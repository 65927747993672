export const enforceMinWidths = (widths) =>
    widths.map((col) => ({
        ...col,
        width: Math.max(col.width, col.minWidth),
    }))

export const recalculateWidths = (currentWidths, totalWidth, hiddenColumns) => {
    const visibleColumns = currentWidths.filter((col) => !hiddenColumns.includes(col.columnName))
    const currentTotal = visibleColumns.reduce((sum, col) => sum + col.width, 0)
    const remainingWidth = totalWidth - currentTotal

    if (remainingWidth >= 0) {
        const recalculatedWidths = visibleColumns.map((col) => {
            const extraWidth = (col.width / currentTotal) * remainingWidth
            return {
                ...col,
                width: col.width + extraWidth,
            }
        })

        return currentWidths.map((col) => {
            const recalculatedCol = recalculatedWidths.find((rc) => rc.columnName === col.columnName)
            return recalculatedCol || col
        })
    }

    return currentWidths.map((col) => ({
        ...col,
        width: Math.max(col.width, col.minWidth),
    }))
}
