import { formatDateTime } from "./FormatDateTime/FormatDateTime";

export const ReportHeaderRight = (
    imageData,
    authUser,
    now,
    reportTitle,
    titleMargin = [175, 0, 0, 0], 
    stackMargin = [0, 0, 0, 0]     
) => {
    return [
        {
            image: imageData,
            width: 70,
        },
        {
            text: reportTitle,
            style: 'header',
            alignment: 'center',
            margin: titleMargin,
        },
        {
            stack: [
                {
                    text: 'Branch: ' + authUser.defaultBranchName,
                    style: 'normal',
                    alignment: 'right',
                    margin: stackMargin, 
                },
                {
                    text: formatDateTime(now),
                    style: 'normal',
                    alignment: 'right',
                    margin: stackMargin,
                },
            ],
            alignment: 'right',
        },
    ];
};
