import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Link, Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { deleteOrder, getOrderById } from 'app/redux/store/features/orders'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useJumboTheme } from '@jumbo/hooks'

const DraftOrdersData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    refresh,
    setCurrentPage,
    apiCallInProgress,
    onRefresh,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { orders, loading, error } = useSelector((state) => state.orders)
    const dateColumns = ['shipmentDate', 'expectedDate', 'receivedDate']
    const [sorting, setSorting] = useState([{ columnName: 'shipmentDate', direction: 'desc' }])
    const pageSize = 25
    const rows = orders.data && orders.data.length > 0 ? orders.data : []
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    const onlineShipment = true

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const hideDialogAndRefreshOrderList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Draft Order data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveOrder = async (orderId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteOrder({ orderId: orderId }))
                hideDialogAndRefreshOrderList()
            },
            onNo: hideDialog,
        })
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getOrderById({ orderId: id, onlineShipment: onlineShipment }))
        navigate(`/store/shipments/orders/details/${id}/${onlineShipment}`, {
            state: { orderId: id, onlineShipment: true },
        })
    }

    const onNavigatingToEdit = (id) => {
        dispatch(getOrderById({ orderId: id, onlineShipment: true }))
        navigate(`/store/shipments/draftorders/edit/${id}/details`, { state: { orderId: id } })
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.orderId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.orderNo}
            </Link>
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        const isDraft = row.orderStatus === 'Draft'

        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.orderId)}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onNavigatingToEdit(row.orderId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                {isDraft && (
                    <IconButton onClick={() => onRemoveOrder(row.orderId)}>
                        <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                )}
            </div>
        )
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.orderId)
                }}
            />
        )
    }

    const columns = [
        { name: 'orderNo', title: 'Order No', getCellValue: renderLinkCell },
        { name: 'orderDate', title: 'Order Date' },
        { name: 'expectedDate', title: 'Delivery Date' },
        { name: 'receivedDate', title: 'Received Date' },
        { name: 'purchaseOrderNo', title: 'Purchase Order no' },
        { name: 'supplierName', title: 'Supplier Name' },
        { name: 'orderStatus', title: 'Status' },
        { name: 'totalAmount', title: 'Total Amount', getCellValue: (row) => formatCurrency(row.txnTotal) },
        { name: 'discountAmount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discount) },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'orderNo', width: min ? '100px' : '8%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'orderDate', width: min ? '110px' : '13%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'expectedDate', width: min ? '110px' : '13%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'receivedDate', width: min ? '110px' : '12%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'purchaseOrderNo', width: min ? '120px' : '11%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'supplierName', wordWrapEnabled: true, width: min ? '170px' : '12%', minWidth: '170px' },
            { columnName: 'orderStatus', width: min ? '90px' : '7%', minWidth: '90px', wordWrapEnabled: true },
            {
                columnName: 'totalAmount',
                align: 'right',
                width: min ? '100px' : '9%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'discountAmount',
                align: 'right',
                width: min ? '100px' : '7%',
                wordWrapEnabled: true,
                minWidth: '100px',
            },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '8%', minWidth: '90px' },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={rows} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={orders.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default DraftOrdersData
