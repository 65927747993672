import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddUserGridForm from './AddUserForm'
import { defaultUserGrid } from './defaultUserGrid'
import { useDispatch, useSelector } from 'react-redux'
import { addUser } from '../../../../redux/store/features/users'
import { useSnackbar } from 'notistack'

const AddUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userData] = useState({ ...defaultUserGrid, userType: null })
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.users)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('User added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/security/users', {
                state: { navigateFrom: 'add-user' },
            })
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddUser = async () => {
        setApiCallInProgress(true)
        dispatch(addUser({ ...userData }))
    }

    return (
        <React.Fragment>
            <AddUserGridForm userData={userData} onAddUser={onAddUser} />
        </React.Fragment>
    )
}

export default AddUser
