import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../../../fonts/NimbusSanL/nimbus-san'
import '../../../../../../reportstyles.css'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import CustomerReceivableReportFilter from './CustomerReceivableReportFilter'
import { useJumboTheme } from '@jumbo/hooks'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { ReportHeaderRight } from 'app/utils/components/ReportHeaderRight'

const CustomerReceivableReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { customersReceivablesReport, loading, error } = useSelector((state) => state.reports)
    const itemsPerPage = 25
    const totalPages = Math.ceil(customersReceivablesReport.totalCount / itemsPerPage)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md
    const { authUser } = useJumboAuth()
    const currentDate = new Date()

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => [
            'Customer Name',
            'Barcode',
            'Customer Category',
            'Phone No',
            'Mobile No',
            'Address',
            'Comments',
            'Inst.Paid On',
            'Amount Paid',
            'Outstanding Amount',
        ],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    d.customerName,
                    d.barcode,
                    d.customerCategory,
                    d.phoneNo,
                    d.mobileNo,
                    d.address,
                    d.comments,
                    formatDateTime(d.lastAmountPaidOn),
                    formatCurrency(d.lastAmountPaid),
                    formatCurrency(d.outStandingAmount),
                ]
            })
        },
        []
    )

    const pageRow = [
        { text: 'Total', style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.lastAmountPaid), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.outStandingAmount), style: 'rightAlign' },
    ]

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: ['cell', index <= 7 ? 'leftAlign' : 'rightAlign'],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 30, 15, 30],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [90, 60, 70, 70, 70, 80, 70, 70, 70, 70],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [20, 10, 30, 10],
                            },
                        ],
                    }
                }

                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [644, 70, 70, 70, 70, 80, 70, 70, 70, 70],
                                body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                            },
                            margin: [15, 5, 15, 20],
                        },
                    ],
                }
            },
            content: [
                {
                    columns: ReportHeaderRight(
                        imageData,
                        authUser,
                        currentDate,
                        'Customers Receivables Report',
                        [205, 0, 0, 0], 
                        [0, 0, 3, 0]
                    ),
                },
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    alignment: 'center',
                    style: 'withMargin',
                    table: {
                        headerRows: 1,
                        widths: [90, 60, 70, 70, 70, 80, 70, 70, 70, 70],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(
            `Customers_Receivables_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`
        )
    }, [pdfTemplate])

    return (
        <Div>
            <Div>
                <h1 className='report-heading'>Customer's Receivables Report</h1>
                <Div className='report-loading-button'>
                    <LoadingButton
                        sx={{ float: 'right', marginRight: '90px', marginTop: '-55px' }}
                        onClick={create}
                        size='small'
                        type='submit'
                        variant='contained'>
                        <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
                    </LoadingButton>
                    <LoadingButton
                        sx={{ float: 'right', marginRight: '9px', marginTop: '-55px' }}
                        onClick={handleFilterClick}
                        size='small'
                        type='submit'
                        variant='contained'>
                        <FilterAltIcon fontSize='medium' />
                    </LoadingButton>
                </Div>
            </Div>
            {isFilterVisible && <CustomerReceivableReportFilter mutation={viewMutation} min={min} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && customersReceivablesReport.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        {headers.map((h, index) => {
                                            return (
                                                <th
                                                    key={h}
                                                    style={{
                                                        whiteSpace: index === 3 ? 'pre' : 'pre-line',
                                                    }}>
                                                    {h}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {customersReceivablesReport.data?.map((d, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: '13px' }}>
                                                <td style={{ width: '130px', textAlign: 'left' }}>{d?.customerName}</td>
                                                <td style={{ width: '90px', textAlign: 'left' }}>{d?.barcode}</td>
                                                <td style={{ width: '100px', textAlign: 'left' }}>
                                                    {d?.customerCategory}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'left' }}>{d?.phoneNo}</td>
                                                <td style={{ width: '90px', textAlign: 'left' }}>{d?.mobileNo}</td>
                                                <td style={{ width: '120px', textAlign: 'left' }}>{d?.address}</td>
                                                <td style={{ width: '110px', textAlign: 'left' }}>{d?.comments}</td>
                                                <td style={{ width: '90px', textAlign: 'left' }}>
                                                    {formatDateTime(d.lastAmountPaidOn)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.lastAmountPaid)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.outStandingAmount)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {customersReceivablesReport.reportSummary && (
                                        <tr style={{ fontSize: '13px' }}>
                                            <td colSpan={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                Total
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(
                                                    customersReceivablesReport.reportSummary.lastAmountPaid
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(
                                                    customersReceivablesReport.reportSummary.outStandingAmount
                                                )}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={totalPages}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default CustomerReceivableReportLayout
