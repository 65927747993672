import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { AddUpdateOrderItems } from 'app/redux/store/features/orders'
import OrderItemAddHeader from './components/OrderItemAddHeader'
import DataSource from 'devextreme/data/data_source'
import DataGrid, { Editing, Paging, Scrolling, ColumnChooser, ColumnChooserSearch } from 'devextreme-react/data-grid'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { defaultOrderItem } from './components/defaultOrderItem'
import uuid from 'draft-js/lib/uuid'
import LocalStore from 'devextreme/data/local_store'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'
import InventoryBasePriceService from './components/InventoryBasePrice'

const searchEditorOptions = { placeholder: 'Search column' }

const OrderItemGrid = (props) => {
    const dispatch = useDispatch()

    const gridRef = useRef(null)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        gridRef.current.instance.refresh(true)
        gridRef.current.instance.cancelEditData()
    }, [props.inventoryItems])

    const onClickSave = useCallback(async () => {
        await gridRef.current.instance.saveEditData()
    }, [])

    const onClickAdd = useCallback(async (data) => {
        let newInventory = {
            ...defaultOrderItem,
            orderItemId: uuid(),
            wholeSaleUntiPrice: data.wholeSaleUntiPrice || defaultOrderItem.wholeSaleUntiPrice,
            wholeSaleUntiPricePercentage:
                data.wholeSaleUntiPricePercentage || defaultOrderItem.wholeSaleUntiPricePercentage,
            fkStockShipmentId: props.orderId,
            invName: data.inventory.inventoryName,
            invNo: data.inventory.inventoryNo,
            inventoryBasePrice: data.inventoryBasePriceData,
            fkInventoryId: data.inventory.inventoryId,
            qtyOrdered: data.qtyOrdered,
            orderPrice: data.orderPrice || defaultOrderItem.orderPrice,
            salePrice: data.salePrice || defaultOrderItem.salePrice,
            discountRate: data.discountRate || defaultOrderItem.discountRate,
            taxRate: data.purchaseTaxRate || defaultOrderItem.taxRate,
            marketPrice: data.marketPrice || defaultOrderItem.marketPrice,
            orderPricePercentage: data.orderPricePercentage || defaultOrderItem.orderPricePercentage,
            salePricePercentage: data.salePricePercentage || defaultOrderItem.salePricePercentage,
            profitMargin: data.inventory.profitMargin || defaultOrderItem.profitMargin,
            fkStockStorageId: data.inventoryStock,
            expiryDate: data.expiryDate,
            additionalTax: data.additionalTax,
            qtyZeroPrice: data.qtyZeroPrice,
        }
        gridRef.current.instance.addRow()
        Object.keys(newInventory).forEach((key) => {
            gridRef.current.instance.cellValue(0, key, newInventory[key]) // Set the new values
        })
        gridRef.current.instance.cellValue(0, 'orderItemId', newInventory.orderItemId)
        gridRef.current.instance.cellValue(0, 'fkStockShipmentId', newInventory.fkStockShipmentId)
        gridRef.current.instance.cellValue(0, 'invName', newInventory.invName)
        gridRef.current.instance.cellValue(0, 'invNo', newInventory.invNo)
        gridRef.current.instance.cellValue(0, 'inventoryBasePrice', newInventory.inventoryBasePrice)
        gridRef.current.instance.cellValue(0, 'fkInventoryId', newInventory.fkInventoryId)
        gridRef.current.instance.cellValue(0, 'qtyOrdered', newInventory.qtyOrdered)
        gridRef.current.instance.cellValue(0, 'orderPrice', newInventory.orderPrice)
        gridRef.current.instance.cellValue(0, 'salePrice', newInventory.salePrice)
        gridRef.current.instance.cellValue(0, 'discountRate', newInventory.discountRate)
        gridRef.current.instance.cellValue(0, 'taxRate', newInventory.taxRate)
        gridRef.current.instance.cellValue(0, 'marketPrice', newInventory.marketPrice)
        gridRef.current.instance.cellValue(0, 'orderPricePercentage', newInventory.orderPricePercentage)
        gridRef.current.instance.cellValue(0, 'salePricePercentage', newInventory.salePricePercentage)
        gridRef.current.instance.cellValue(0, 'profitMargin', newInventory.profitMargin)
        gridRef.current.instance.cellValue(0, 'fkStockStorageId', newInventory.fkStockStorageId)
        gridRef.current.instance.cellValue(0, 'expiryDate', newInventory.expiryDate)
        gridRef.current.instance.cellValue(0, 'wholeSaleUntiPrice', newInventory.wholeSaleUntiPrice)
        gridRef.current.instance.cellValue(0, 'wholeSaleUntiPricePercentage', newInventory.wholeSaleUntiPricePercentage)
        gridRef.current.instance.cellValue(0, 'additionalTax', newInventory.additionalTax)
        gridRef.current.instance.cellValue(0, 'qtyZeroPrice', newInventory.qtyZeroPrice)
    }, [])

    const OrderItemStore = useMemo(() => {
        return new LocalStore({
            key: 'orderItemId',
            data: props.inventoryItems?.data ?? [],
            name: 'transferItems',
        })
    }, [props.inventoryItems])

    const OrderItemDataSource = useMemo(() => {
        return new DataSource({
            store: OrderItemStore,
            reshapeOnPush: true,
        })
    }, [])

    async function sendBatchRequest(changes) {
        let order = {
            orderId: props.orderId,
            inventoryItems: [],
            removeItems: [],
        }
        let gridRows = await OrderItemStore.load()
        changes.forEach((c) => {
            if (c.type === 'remove') {
                order.removeItems.push({ orderItemId: c.key })
                return
            }
            let row = c.type === 'update' ? gridRows.find((entry) => entry.orderItemId === c.key) : {}
            order.inventoryItems.push({
                orderItemId: c?.key?.replace('_DX_KEY_', '') ?? row?.orderItemId,
                fkStockShipmentId: props.orderId,
                invName: c?.data?.invName ?? row?.invName,
                invNo: c?.data?.invNo ?? row?.invNo,
                inventoryBasePrice: c?.data?.inventoryBasePrice ?? row?.inventoryBasePrice,
                fkInventoryId: c?.data?.fkInventoryId ?? row?.fkInventoryId,
                qtyOrdered: c?.data?.qtyOrdered ?? row?.qtyOrdered,
                orderPrice: c?.data?.orderPrice ?? row?.orderPrice,
                salePrice: c?.data?.salePrice ?? row?.salePrice,
                discountRate: c?.data?.discountRate ?? row?.discountRate,
                taxRate: c?.data?.taxRate ?? row?.taxRate,
                marketPrice: c?.data?.marketPrice ?? row?.marketPrice,
                orderPricePercentage: c?.data?.orderPricePercentage ?? row?.orderPricePercentage ?? null,
                salePricePercentage: c?.data?.salePricePercentage ?? row?.salePricePercentage ?? null,
                profitMargin: c?.data?.profitMargin ?? row?.profitMargin,
                fkStockStorageId: c?.data?.fkStockStorageId ?? row?.fkStockStorageId ?? null,
                qtyZeroPrice: c?.data?.qtyZeroPrice ?? row?.qtyZeroPrice,
                wholeSaleUntiPrice: c?.data?.wholeSaleUntiPrice ?? row?.wholeSaleUntiPrice,
                wholeSaleUntiPricePercentage:
                    c?.data?.wholeSaleUntiPricePercentage ?? row?.wholeSaleUntiPricePercentage ?? null,
                additionalTax: c?.data?.additionalTax ?? row?.additionalTax,
                expiryDate: c?.data?.expiryDate ?? row?.expiryDate ?? null,
            })
        })
        dispatch(AddUpdateOrderItems(order))
    }

    async function processBatchRequest(changes, component) {
        await sendBatchRequest(changes)
    }

    const onSaving = (e) => {
        e.cancel = true
        if (e.changes.length) {
            e.promise = processBatchRequest(e.changes, e.component)
        }
    }

    const columns = [
        { dataField: 'invNo', caption: 'Inventory No', allowEditing: false },
        { dataField: 'invName', caption: 'Product Name', allowEditing: false },
        {
            dataField: 'storageName',
            visible: false,
            caption: 'Stock Location',
            allowEditing: false,
        },
        {
            dataField: 'qtyOrdered',
            caption: 'Qty Received',
            dataType: 'number',
            alignment: 'center',
            allowEditing: props.orderStatus === 'Draft',
        },
        {
            dataField: 'qtyZeroPrice',
            visible: false,
            caption: 'Bonus Quantity',
            allowEditing: false,
        },
        {
            dataField: 'expiryDate',
            visible: false,
            caption: 'Expiry Date',
            dataType: 'date',
            allowEditing: false,
        },
        {
            dataField: 'orderPrice',
            caption: 'Order Price',
            dataType: 'number',
            alignment: 'right',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'inventoryBasePrice',
            caption: 'Base Price',
            alignment: 'center',
            allowEditing: false,
            calculateCellValue: (rowData) =>
                InventoryBasePriceService.getBasePriceServiceById(rowData.inventoryBasePrice),
        },
        {
            dataField: 'salePrice',
            caption: 'Sale Price',
            dataType: 'number',
            alignment: 'right',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'discountRate',
            caption: 'Discount',
            dataType: 'number',
            alignment: 'right',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'taxRate',
            caption: 'Tax',
            dataType: 'number',
            alignment: 'right',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'additionalTax',
            visible: false,
            caption: 'Additional Tax',
            allowEditing: false,
        },
        {
            dataField: 'wholeSaleUntiPricePercentage',
            visible: false,
            caption: 'Wholesale Price %',
            allowEditing: false,
        },
        {
            dataField: 'wholeSaleUntiPrice',
            visible: false,
            caption: 'Wholesale Price',
            allowEditing: false,
        },
        {
            dataField: 'marketPrice',
            caption: 'Market Price',
            dataType: 'number',
            alignment: 'right',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'orderPricePercentage',
            caption: 'Order Price %',
            dataType: 'number',
            alignment: 'center',
            allowEditing: props.orderStatus === 'Draft',
            format: (data) => formatCurrency(data),
        },
        {
            dataField: 'salePricePercentage',
            visible: false,
            caption: 'Sale Price %',
            allowEditing: false,
        },
        {
            dataField: 'profitMargin',
            caption: 'Profit Margin',
            alignment: 'right',
            allowEditing: false,
        },
        {
            dataField: 'totalAmount',
            caption: 'Total',
            alignment: 'right',
            allowEditing: false,
            format: (data) => formatCurrency(data),
        },
    ]

    const handleContentReady = (e) => {
        console.log('e = ', e)
        const columnChooserView = e.component.getView('columnChooserView')
        if (columnChooserView && !columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer()
            columnChooserView.render()
            columnChooserView._popupContainer.option('dragEnabled', false)
        }
    }

    return (
        <div>
            <Grid sx={{ marginTop: '-24px', marginBottom: '24px' }}>
                <Grid sx={{ marginBottom: sm ? '24px' : '' }}>
                    <OrderItemAddHeader
                        onClickAdd={onClickAdd}
                        onClickSave={onClickSave}
                        isDraftStatus={props.orderStatus}
                    />
                </Grid>
                <Grid>
                    <DataGrid
                        ref={gridRef}
                        height={380}
                        dataSource={OrderItemDataSource}
                        remoteOperations={true}
                        onSaving={onSaving}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        columnWidth={122}
                        allowColumnResizing={true}
                        columnResizingMode='nextColumn'
                        defaultColumns={columns}
                        onContentReady={handleContentReady}>
                        <Scrolling columnRenderingMode='virtual' />
                        <Paging enabled={false} />
                        <Editing
                            mode='batch'
                            allowUpdating={true}
                            allowDeleting={props.orderStatus === 'Approved' ? false : true}
                            useIcons={true}
                            startEditAction={'dblClick'}
                        />
                        <ColumnChooser height='340px' enabled={true} mode='select'>
                            <ColumnChooserSearch enabled={true} editorOptions={searchEditorOptions} />
                        </ColumnChooser>
                    </DataGrid>
                </Grid>
            </Grid>
        </div>
    )
}

export default OrderItemGrid
