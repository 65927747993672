import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, PagingPanel, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency.js'
import useJumboTheme from '../../../../../../../../../../@jumbo/hooks/useJumboTheme'
import { useMediaQuery } from '@mui/material'

const TransferItemListData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    transferItemsData,
}) => {
    const pageSize = 10
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, error } = useSelector((state) => state.transfers)
    const dateColumns = ['updatedDate']
    const [sorting, setSorting] = useState([{ columnName: 'cartName', direction: 'asc' }])
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [loading]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'cartName', title: 'Cart Name' },
        { name: 'invBarcode', title: 'Barcode' },
        { name: 'requestedQty', title: 'Requested' },
        { name: 'dispatchedQty', title: 'Dispatched' },
        { name: 'receivedQty', title: 'Received' },
        { name: 'qtyAdjusted', title: 'Adjusted' },
        { name: 'boxQuantity', title: 'Box Qty' },
        {
            name: 'senderPurchasePrice',
            title: 'Purchase Price',
            getCellValue: (row) => formatCurrency(row.senderPurchasePrice),
        },
        { name: 'senderSalePrice', title: 'Sale Price', getCellValue: (row) => formatCurrency(row.senderSalePrice) },
        { name: 'subTotal', title: 'Sub Total', getCellValue: (row) => formatCurrency(row.subTotal) },
        { name: 'updatedDate', title: 'Date Updated' },
        { name: 'comments', title: 'Comments' },
    ]

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'cartName',
                width: min ? '110px' : '9%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'invBarcode',
                width: min ? '100px' : '9%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'requestedQty',
                width: min ? '90px' : '8%',
                minWidth: '90px',
                align: 'center',
                wordWrapEnabled: true,
            },
            {
                columnName: 'dispatchedQty',
                wordWrapEnabled: true,
                width: min ? '90px' : '8%',
                minWidth: '90px',
                align: 'center',
            },
            {
                columnName: 'receivedQty',
                wordWrapEnabled: true,
                width: min ? '90px' : '7%',
                minWidth: '90px',
                align: 'center',
            },
            {
                columnName: 'qtyAdjusted',
                wordWrapEnabled: true,
                width: min ? '80px' : '7%',
                minWidth: '80px',
                align: 'center',
            },
            {
                columnName: 'boxQuantity',
                width: min ? '80px' : '7%',
                minWidth: '80px',
                wordWrapEnabled: true,
                align: 'center',
            },
            {
                columnName: 'senderPurchasePrice',
                width: min ? '120px' : '9%',
                minWidth: '100px',
                align: 'right',
                wordWrapEnabled: true,
            },
            {
                columnName: 'senderSalePrice',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                align: 'right',
                wordWrapEnabled: true,
            },
            {
                columnName: 'subTotal',
                width: min ? '80px' : '8%',
                minWidth: '80px',
                align: 'right',
                wordWrapEnabled: true,
            },
            { columnName: 'updatedDate', width: min ? '120px' : '12%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'comments', width: min ? '120px' : '8%', minWidth: '100px', wordWrapEnabled: true },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={transferItemsData.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={transferItemsData.totalCount} />
                    <Table columnExtensions={columnWidths} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default TransferItemListData
