import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Span from '@jumbo/shared/Span'
import { Grid } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import PeopleIcon from '@mui/icons-material/People'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import { VscReferences } from 'react-icons/vsc'
import { TbUserSquareRounded } from 'react-icons/tb'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { formatDate } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import WestIcon from '@mui/icons-material/West'

const RequestHeaderCard = ({ request }) => {
    const navigate = useNavigate()
    return (
        <Grid>
            <Grid container spacing={3.75}>
                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1 }}>
                                <IconButton
                                    onClick={() =>
                                        navigate(`/company/transfers/requests`, {
                                            state: { navigateFrom: 'cancel-detail-requests' },
                                        })
                                    }
                                    sx={{ color: 'white' }}>
                                    <WestIcon />
                                </IconButton>
                                <Typography
                                    sx={{ textAlign: 'center', flexGrow: 1 }}
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    variant={'h3'}>
                                    {request.transferRequestNo}{' '}
                                    <TransferWithinAStationIcon
                                        color='common.white'
                                        sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }}
                                    />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'success.dark',
                        }}>
                        <Typography fontFamily='Lobster, cursive' color='success.dark' variant={'h4'}>
                            <DateRangeIcon fontSize='large' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                            <Span>{`${formatDate(request.transferRequestDate)}`}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>

                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    Staff Name{' '}
                                    <PeopleIcon color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'primary.dark',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='primary.dark'>
                            <TbUserSquareRounded fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span sx={{ ml: 1 }}>{request.staffName}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    {' '}
                                    Transfer Status{' '}
                                    <MoveUpIcon color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'warning.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='warning.main'>
                            <Span sx={{ ml: 1 }}>{request.transferStatusName}</Span>
                            <CheckCircleOutlineIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    {' '}
                                    Reference Number{' '}
                                    <VscReferences color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'info.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='info.dark'>
                            <Span sx={{ ml: 1 }}>{request.refNumber ? request.refNumber : '-'}</Span>
                            <VscReferences fontSize='large' sx={{ mt: -3, mb: -1, ml: 1.5 }} />
                        </Typography>
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RequestHeaderCard
